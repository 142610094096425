.container {
    position: relative; 
}

.container video {
    position: relative; 
    z-index: 0;
}

.overlay {
    position: absolute;
    font-size: 29px;
    top: 300px;
    left: 250px;
    z-index: 1;
    color: white;
}

.clientes {
    position: flex; 
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin: auto;
    text-align: center;
}

.clientes p {
    color: white; 
    font-size: 5vh;
}

.clientes-box {
    background-color: '#1A1A1A';
}

.clientes-box img {
    width: 20%;
}

.clientes-box:hover {
    cursor: pointer; 
}

.clientes-box img+img {
    margin: 35px;
}