body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1F1F1F;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
    position: relative; 
}

.container video {
    position: relative; 
    z-index: 0;
}

.overlay {
    position: absolute;
    font-size: 29px;
    top: 300px;
    left: 250px;
    z-index: 1;
    color: white;
}

.clientes {
    position: flex; 
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin: auto;
    text-align: center;
}

.clientes p {
    color: white; 
    font-size: 5vh;
}

.clientes-box {
    background-color: '#1A1A1A';
}

.clientes-box img {
    width: 20%;
}

.clientes-box:hover {
    cursor: pointer; 
}

.clientes-box img+img {
    margin: 35px;
}
footer {
    display: flex;
    flex-direction: column;
    position: relative; 
    padding: 10px 10px 0px 10px; 
    bottom: 0;
    width: 100%; 
    height: auto;
    background: #151515; 
}

footer h1 {
    color: white;
}

.footer-primary {
    display: flex;
    flex-direction: column;
    float: left;
    width: 25%;
}

.footer-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 50%;
    float: left;
}

.footer-center img{
    width: 10%;
}

.footer-primary p {
    margin: auto;
    color: white;
}

.footer-contact h2, h3, p{
    color: white;
    display: flex;
}

.footer-contact {
    float: right;
    width: 25%;
}

.footer-contact img {
    width: 10%;
}

.footer-contact a+a {
    margin-left: 10px;
}




.centralized {
    text-align: center;
    justify-content: center;
    align-self: center;
}

.mapouter {
    position:relative;text-align:right;height:215px;width:203px;
}

.gmap_canvas {
    overflow:hidden;background:none!important;height:215px;width:203px
};
