footer {
    display: flex;
    flex-direction: column;
    position: relative; 
    padding: 10px 10px 0px 10px; 
    bottom: 0;
    width: 100%; 
    height: auto;
    background: #151515; 
}

footer h1 {
    color: white;
}

.footer-primary {
    display: flex;
    flex-direction: column;
    float: left;
    width: 25%;
}

.footer-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 50%;
    float: left;
}

.footer-center img{
    width: 10%;
}

.footer-primary p {
    margin: auto;
    color: white;
}

.footer-contact h2, h3, p{
    color: white;
    display: flex;
}

.footer-contact {
    float: right;
    width: 25%;
}

.footer-contact img {
    width: 10%;
}

.footer-contact a+a {
    margin-left: 10px;
}




.centralized {
    text-align: center;
    justify-content: center;
    align-self: center;
}

.mapouter {
    position:relative;text-align:right;height:215px;width:203px;
}

.gmap_canvas {
    overflow:hidden;background:none!important;height:215px;width:203px
};